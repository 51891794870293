import parquetjs from '@dsnp/parquetjs'
import { fetchGzip } from '@cd/screen-data'
import type { GeometryType } from '@/types/index.types'
import type { CellsData } from '@/types/maps.types'

async function getParquet(url: string): Promise<any[]> {
  const buffer = await fetchGzip(url)
  // @ts-expect-error because the type definition is wrong
  const reader = await parquetjs.ParquetReader.openBuffer(buffer)

  const cursor = reader.getCursor()

  const res = []

  let record = await cursor.next()
  while (record) {
    res.push(record)
    record = await cursor.next()
  }

  await reader.close()

  return res
}

const METRICS: Record<string, { breaks: number[] }> = {
  pollution: {
    breaks: [
      8.721639001225851,
      16.70969186346215,
      21.346181398279526,
      26.244530034619707,
      32.41203280529344,
    ],
  },
}

async function getMetric(metric: string, layer: GeometryType = GeometriesTypes.H3_8) {
  const url = `https://screen-data-staging.s3.nl-ams.scw.cloud/barcelona/test/${metric}_${layer}.parquet`
  const raw = await getParquet(url)

  const key = Object.keys(raw[0])[0]

  const data = raw.map(record => record[key])

  const m = METRICS[metric]
  return {
    ...m,
    data,
  }
}

async function getIndexes(layer: GeometryType = GeometriesTypes.H3_8) {
  const url = `https://screen-data-staging.s3.nl-ams.scw.cloud/barcelona/test/${layer}.parquet`
  const data = await getParquet(url)

  const key = Object.keys(data[0])[0]

  return data.map(record => record[key])
}

function mapMetric(indexes: string[], metrics: number[], breaks: number[]) {
  const res = []
  for (let i = 0; i < indexes.length; i++) {
    const h3 = indexes[i]
    const metric = metrics[i]
    const classBreak = breaks.findIndex(b => metric < b)

    res.push({ h3, metric, classBreak })
  }
  return res
}

// export async function getParquetH3Indexes(h3: GeometryType = GeometriesTypes.H3_10): Promise<string[]> {
//   const indexes = await getIndexes(h3)

//   return indexes.map(index => index.toString(16))
// }

// export async function getParquetH3Metric(h3: GeometryType = GeometriesTypes.H3_10, name: string = 'pollution'): Promise<number[]> {
//   return getMetric(name, h3)
// }

export async function getMapOfCellData(h3: GeometryType = GeometriesTypes.H3_10, name: string = 'pollution'): Promise<CellsData> {
  const indexes = await getIndexes(h3)
  const metrics = await getMetric(name, h3)

  const mapped = mapMetric(indexes, metrics.data, metrics.breaks)

  const map = new Map()
  for (let i = 0; i < mapped.length; i++) {
    let { h3, metric } = mapped[i]

    // @ts-expect-error because the type definition is wrong
    h3 = h3.toString(16)

    // TODO: probably more efficient to use directly indexes[i].h3
    map.set(h3, metric)
  }

  return map
}
