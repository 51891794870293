<script setup lang="ts">
import { VehicleTypes } from '@/types/vehicleTypes.types'
import type { ModeOrAll } from '@/types/modes.types'
import type { Color } from '@/utils/colors'
import type { DSelectPickerOption } from '@/components/ui/DSelectPicker/DSelectPicker.vue'

interface ModesOptions extends DSelectPickerOption {
  label: string
  value: ModeOrAll
  icon: string
  color: Color | false
}

const { t } = useI18n()

const defaultOptions: ModesOptions[] = [
  {
    label: t('All modes'),
    value: VehicleTypes.ALL,
    icon: 'all-modes',
    color: false,
  },
  {
    label: t('Bikes'),
    value: VehicleTypes.BIKE,
    icon: 'bike',
    color: PALETTE_COLORS_VEHICLES[VehicleTypes.BIKE],
  },
  {
    label: t('Scooters'),
    value: VehicleTypes.SCOOTER,
    icon: 'scooter',
    color: PALETTE_COLORS_VEHICLES[VehicleTypes.SCOOTER],
  },
  {
    label: t('Mopeds'),
    value: VehicleTypes.MOTORSCOOTER,
    icon: 'moped',
    color: PALETTE_COLORS_VEHICLES[VehicleTypes.MOTORSCOOTER],
  },
  {
    label: t('Cars'),
    value: VehicleTypes.CAR,
    icon: 'car',
    color: PALETTE_COLORS_VEHICLES[VehicleTypes.CAR],
  },
]

const { city } = storeToRefs(useCityStore())
const { provider } = storeToRefs(useProviderStore())

const route = useRoute()
const router = useRouter()

const selectedMode = computed({
  get: (): ModeOrAll => {
    return route.params.mode as ModeOrAll || VehicleTypes.ALL
  },
  set: (value: ModeOrAll) => {
    router.push({ params: { mode: value || VehicleTypes.ALL } })
  },
})

const { vehicleTypes } = useVehicleTypesFromCityOrProvider(city, provider)

const options = computed(() => {
  const vehicleTypesRef = get(vehicleTypes)

  return defaultOptions.filter((option) => {
    const optionValue = option.value
    return (
      (optionValue !== VEHICLE_TYPE_ALL
      && vehicleTypesRef?.includes(ENUM_VEHICLE_TYPES[optionValue]))
      || (optionValue === VEHICLE_TYPE_ALL && vehicleTypesRef && vehicleTypesRef.length > 1)
    )
  })
})

watch(
  () => route.params.mode,
  (nextValue, prevValue) => {
    if (nextValue !== prevValue) {
      set(selectedMode, route.params.mode || VehicleTypes.ALL)
    }
  },
)

onMounted(() => {
  globalEmitter.on('filters:reset', () => {
    set(selectedMode, get(options)[0]?.value || VehicleTypes.ALL)
  })
})

onBeforeUnmount(() => {
  globalEmitter.off('filters:reset')
})
</script>

<template>
  <DSelectPicker
    v-if="Array.isArray(vehicleTypes)"
    id="mode"
    v-model="selectedMode"
    :options="options"
    :label="t('Vehicle type')"
    icon="wheel"
  />
</template>
