<script setup>
import { ENUM_LOCATION_TYPES } from '@/utils/constants'
import providersQuery from '@/graphql/queries/providers.gql'

const { t } = useI18n()
const { isFreemium, settings } = storeToRefs(useUserStore())
const filtersStore = useFiltersStore()
const { getPref } = usePreferences()

const sortByOptions = [
  { value: 'slug', text: t('Alphabetical Order') },
  { value: SORT_BY_AVAILABLE_VEHICLES, text: t('Available vehicles') },
  { value: SORT_BY_TOTAL_COUNTRIES, text: t('Countries') },
  { value: SORT_BY_TOTAL_CITIES, text: t('Cities') },
]

const viewType = ref(getPref('boards.global.viewType') || 'list')
const providersShallowed = shallowRef([])

const {
  sortBy,
  vehicleTypesSelected,
  locationsSelected,
  showLocked,
  filterAndSort,
  resetFilters,
  getVariableFromFilter,
} = useCityProviderFilters()

const variables = computed(() => ({
  dateRange: filtersStore.dateRangeStr,
  cities: getVariableFromFilter(
    locationsSelected.value,
    ENUM_LOCATION_TYPES.city,
  ),
  countries: getVariableFromFilter(
    locationsSelected.value,
    ENUM_LOCATION_TYPES.country,
  ),
  continents: getVariableFromFilter(
    locationsSelected.value,
    ENUM_LOCATION_TYPES.continent,
  ),
  regions: getVariableFromFilter(
    locationsSelected.value,
    ENUM_LOCATION_TYPES.region,
  ),
  vehicleTypes: vehicleTypesSelected.value.map(
    vehicleType => ENUM_VEHICLE_TYPES[vehicleType],
  ),
}))

const { onResult, loading: isLoading, error } = useQuery(
  providersQuery,
  variables,
  {
    debounce: 200,
    returnPartialData: false,
  },
)

onResult(async (result) => {
  if (result.partial) {
    return
  }

  const providers = result.data?.providers.slice(0) || []
  set(providersShallowed, providers)
})

const favoriteProviders = computed(() => {
  if (providersShallowed.value.length) {
    return (
      get(settings)
        ?.favorites?.providers?.map(value =>
          providersShallowed.value.find(provider => provider.slug === value),
        )
        .filter(provider => provider)
        .sort(sortByAttr('slug')) || []
    )
  }
  return []
})

const filteredProviders = computed(() => {
  let providers = providersShallowed.value

  if (providers.length > 0) {
    providers = filterAndSort(providers)
  }

  return providers
})

const monitoredOrNotTotals = computed(() => {
  const count = providersShallowed.value.reduce(
    (total, provider) => total + provider.availableInCD,
    0,
  )

  return {
    notMonitored: providersShallowed.value.length - count,
    monitored: count,
  }
})
</script>

<template>
  <div>
    <SidebarContainer>
      <template #left>
        <Sidebar @reset="() => resetFilters()">
          <template #filters>
            <FilterBy
              v-model:vehicle-types="vehicleTypesSelected"
              v-model:locations="locationsSelected"
            />
            <FilterSortBy
              v-model="sortBy"
              :options="sortByOptions"
            />
            <FilterLocked v-model="showLocked" />
          </template>
        </Sidebar>
      </template>

      <BoardTitle class="mt-8">
        {{ t('Explore operators') }}
        <FiltersIcons :filters="['dateRange', 'filterBy', 'sortBy']" />

        <template #right>
          <RowCardViewTypeSelector
            v-model="viewType"
            v-memo="[viewType]"
          />
        </template>
      </BoardTitle>

      <DAlert
        v-if="isFreemium"
        id="providers-freemium"
        variant="warning"
        closable
      >
        {{ t('only_plan_can_access_operator', { subscriptionPlan: t('data.plans.premium') }) }}
        <template #subtitle>
          <I18nMd
            keypath="test_demo_or_upgrade"
            tag="p"
          >
            <template #demo="{ translation }">
              <DLink
                :to="{ name: 'Provider', params: { provider: DEMO } }"
                class="underline"
              >
                {{ translation }}
              </DLink>
            </template>
            <template #upgrade="{ translation }">
              <DLink
                :to="{ name: 'SubscribePlans' }"
                class="underline"
              >
                {{ translation }}
              </DLink>
            </template>
          </I18nMd>
        </template>
      </DAlert>

      <DAlert
        v-else
        id="providers"
        variant="info"
        closable
      >
        {{
          t(
            'You can only access data from operators that are present in at least one city in your plan.',
          )
        }}
        <template #subtitle>
          <I18nMd
            keypath="upgrade_to_see_more_operators"
            tag="p"
          >
            <template #link="{ translation }">
              <DLink
                :to="{ name: 'SubscribePlans' }"
                class="underline"
              >
                {{ translation }}
              </DLink>
            </template>
          </I18nMd>
        </template>
      </DAlert>

      <template v-if="favoriteProviders?.length">
        <RowCardTitle>
          <template #header>
            {{ t('starred') }}
          </template>
          <template #header-right>
            <span class="font-bold">{{ favoriteProviders.length }}</span>
            {{ t('starred') }}
          </template>
        </RowCardTitle>
        <RowCardContainer :view-type="viewType">
          <ProviderLink
            v-for="provider in favoriteProviders"
            :key="provider.slug"
            :provider="provider"
          >
            <ProviderRowCard
              :provider="provider"
              :selected-vehicles="vehicleTypesSelected"
              :mode="viewType"
            />
            <template #modal="{ close }">
              <FreemiumSeeDemoOrUpgradeModal @close="() => close()" />
            </template>
          </ProviderLink>
        </RowCardContainer>
      </template>

      <RowCardTitle>
        <template #header>
          {{ t('Operators', filteredProviders.length) }}
          <DLoader
            v-if="isLoading"
            size="sm"
            inline
          />
        </template>
        <template
          v-if="!isLoading"
          #header-right
        >
          <span class="font-bold">{{ filteredProviders.length }}</span>
          {{ t('matches', filteredProviders.length) }}<br>
          <small class="text-xs text-grey-300">
            <span class="text-blue-500">
              {{ monitoredOrNotTotals.monitored }} {{ t('monitored') }}
            </span>
            / {{ monitoredOrNotTotals.notMonitored }} {{ t('not monitored') }}
          </small>
        </template>

        <div v-if="isLoading && filteredProviders.length === 0">
          <PlaceHolder>
            <PlaceHolderCard />
          </PlaceHolder>
        </div>

        <DAlert
          v-if="!isLoading && error"
          variant="alert"
          closable
        >
          {{ t('An error occurred !') }}
        </DAlert>

        <p
          v-if="!isLoading && filteredProviders.length === 0"
          class="text-center text-sm text-grey-500"
        >
          {{ t('No operators found') }}
        </p>
      </RowCardTitle>

      <RowCardContainer :view-type="viewType">
        <ProviderLink
          v-for="provider in filteredProviders"
          :key="provider.slug"
          :provider="provider"
        >
          <ProviderRowCard
            :provider="provider"
            :selected-vehicles="vehicleTypesSelected"
            :mode="viewType"
          />
          <template #modal="{ close }">
            <FreemiumSeeDemoOrUpgradeModal @close="() => close()" />
          </template>
        </ProviderLink>
      </RowCardContainer>
    </SidebarContainer>
  </div>
</template>
