<script setup lang="ts">
import Barcelona from './cities/Barcelona.vue'
import Sarajevo from './cities/Sarajevo.vue'

defineOptions({
  name: 'CyclabilityOverview',
})

const { t } = useI18n()
const route = useRoute()

const availableScreenCities: Record<string, Component> = {
  barcelona: Barcelona,
  sarajevo: Sarajevo,
}

const citySlug = computed(() => route.params.citySlug as string)
</script>

<template>
  <div class="overview">
    <BoardTitle hide-on-mobile>
      {{ t('Cyclability Report') }}
    </BoardTitle>

    <component
      :is="availableScreenCities[citySlug]"
      v-if="availableScreenCities[citySlug]"
    />
  </div>
</template>
