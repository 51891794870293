<script setup lang="ts">
import type { LngLatLike } from 'maplibre-gl'

defineOptions({
  name: 'CyclabilityMap',
})

const props = withDefaults(defineProps<CyclabilityMapProps>(), {
  zoom: 7,
  center: () => [-0.09, 51.505],
  data: () => ({
    geojson: {
      type: 'FeatureCollection',
      features: [],
    },
  }),
})

interface CyclabilityMapProps {
  zoom: number
  center: LngLatLike
  data: {
    geojson: GeoJSON.FeatureCollection
  }
  legendTitle?: string
  loading?: boolean
}

const { selectedArea }
  = useSelectedAreaFromUrl()

const geojson = computed(
  () =>
    props.data?.geojson || {
      type: 'FeatureCollection',
      features: [],
    },
)

const { classBreaks } = useClassBreaks(useExtractMean(geojson))
const colorBreaks = useExpressionColorFromBreaks(classBreaks)

const paintExpression = computed(() => {
  return {
    'line-color': colorBreaks.value,
    'line-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.6,
      1,
    ],
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      12,
      8,
    ],
  }
})

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <MapLibre
    :zoom="zoom"
    :center="center"
    :min-zoom="3"
    :max-zoom="17"
  >
    <MapLibrePopup
      :offset="[0, -5]"
      layer-id="cyclability-map"
    >
      <template #element="{ feature }">
        <div class="flex w-28 flex-col whitespace-normal text-xs">
          <p>{{ feature?.properties?.name }}</p>
        </div>
      </template>
    </MapLibrePopup>

    <MapLibreSourceGeojson
      v-if="!loading"
      id="city-segments"
      :layer-props="{
        type: 'line',
        paint: paintExpression,
        layout: {
          'line-cap': 'square', // round | butt | square
          'line-join': 'round', // round | bevel | miter
        },
      }"
      :data="geojson"
      :geojson-options="{
        promoteId: 'name',
      }"
      :selected-features="selectedArea || []"
    />

    <CityNetwork />

    <MapSelectArea :layers-ids="['city-segments']" />

    <DefineTemplate>
      <DLoader v-if="loading" />
      <MapLegend
        v-else
        :class-breaks="classBreaks"
      >
        <template
          v-if="legendTitle"
          #title
        >
          {{ legendTitle }}
        </template>
      </MapLegend>
    </DefineTemplate>

    <SidebarMobileControls>
      <template #default="{ isMobile }">
        <MapLibreControl
          v-if="!isMobile"
          position="bottom-left"
        >
          <ReuseTemplate />
        </MapLibreControl>
      </template>

      <template #mobile>
        <ReuseTemplate />
      </template>
    </SidebarMobileControls>
  </MapLibre>
</template>
