<script setup lang="ts">
import { ROAD_TYPES, getNetwork } from '@cd/screen-data'

defineOptions({
  name: 'CityNetwork',
})

const loading = ref(true)

interface NetworkType {
  roadType: string
  lines: [[number, number][]]
}

interface NetwotkProperties {
  infra_type_key: number
}

type NetworkFeature = GeoJSON.Feature<GeoJSON.LineString, NetwotkProperties>

const network = shallowRef<NetworkType[]>([])

// const typesBB = [
//   'Limited access roads',
//   'Active roads',
//   'Main roads',
//   'Local roads',
//   'Two-way streets',
//   'One-way streets with contraflow cycling',
//   'Cycle tracks',
//   'Pedestrian track with cycling allowed',
//   'Cycle lanes',
//   'Bus and cycle lanes',
//   'Cycle and pedestrian tracks',
//   'Cycle streets',
// ]

const types = ROAD_TYPES

const { classBreaks } = useClassBreaks(Object.keys(types).map(n => Number(n)), types.length - 1, 'YlGnBu')
const paintColor = useExpressionColorFromBreaks(classBreaks, 'infra_type_key')

const geojson = shallowRef<GeoJSON.FeatureCollection<GeoJSON.LineString, NetwotkProperties> | null>(null)

onMounted(() => {
  retrieveNetwork()
})

function makeGeojson() {
  geojson.value = {
    type: 'FeatureCollection',
    features: network.value.reduce<NetworkFeature[]>((acc, networkType) => {
      const features = networkType.lines.map((line) => {
        return {
          type: 'Feature',
          properties: {
            infra_type_key: types.indexOf(networkType.roadType),
          },
          geometry: {
            type: 'LineString',
            coordinates: line,
          },
        } as NetworkFeature
      })

      return [...acc, ...features]
    }, []),
  }
}

async function retrieveNetwork() {
  // add city in path `route.params.citySlug`
  const url = `https://screen-data-staging.s3.nl-ams.scw.cloud/barcelona/test_network/amb_geom_bike_network_osm_SIMPLIFICATION_10_MAX_LENGTH_200_NB_EXPLODED_197113_ID_44.bin`

  await getNetwork(url, (data: NetworkType) => {
    const existIndex = network.value.findIndex((d) => {
      return d.roadType === data.roadType
    })

    const tmp = network.value

    if (existIndex !== -1) {
      tmp[existIndex] = {
        roadType: data.roadType,
        lines: data.lines || [],
      }
    } else {
      tmp.push({
        roadType: data.roadType,
        lines: data.lines || [],
      })
    }

    network.value = tmp
  })

  // all data loaded
  loading.value = false

  nextTick(() => {
    makeGeojson()
  })
}
</script>

<template>
  <MapLibreSourceGeojson
    v-if="geojson && !loading"
    id="city-network"
    :layer-props="{
      type: 'line',
      beforeId: 'cyclability-map',
      paint: {
        'line-width': 1,
        'line-opacity': 1,
        'line-color': paintColor || '#576067',
      },
    }"
    :data="geojson"
  />

  <MapLibreControl>
    <MapLegend
      :class-breaks="classBreaks"
      :labels="types"
    >
      <template #label="{ value }">
        {{ types[value] }}
      </template>
    </MapLegend>
  </MapLibreControl>
</template>
