const screenGeomtries = [
  {
    cities: ['barcelona'],
    type: GeometriesTypes.H3_10,
  },
  {
    cities: ['barcelona'],
    type: GeometriesTypes.H3_11,
  },
]

export function useCyclability(citySlug: Ref<string | null>) {
  const route = useRoute()
  const { translateFromData } = useLabelTranslation()

  const options = reactive({
    dataScale: 5,
    geometryType: getGeometryTypeFromUrl(route.params.areas as string),
  })

  const availableGeometries = computed(() => {
    if (citySlug.value === null) {
      return []
    }

    return screenGeomtries
      .filter(g => g.cities.includes(citySlug.value as string))
      .map(g => ({
        ...g,
        label: translateFromData('geometry', g.type),
      }))
  })

  return {
    options,
    availableGeometries,
  }
}
