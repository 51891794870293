interface localeConfig {
  key: string
  beta?: boolean
  admin?: boolean
}

const locales: localeConfig[] = [
  {
    key: 'bs',
    beta: true,
  },
  {
    key: 'de',
    beta: true,
  },
  {
    key: 'en-GB',
  },
  {
    key: 'en-US',
  },
  {
    key: 'es',
    beta: true,
  },
  {
    key: 'fr',
    beta: true,
  },
  {
    key: 'it',
    beta: true,
  },
  {
    key: 'pl',
    beta: true,
  },
  {
    key: 'pt',
    beta: true,
  },
  {
    key: 'zh-Hans',
    beta: true,
    admin: true,
  },
]

export default locales
