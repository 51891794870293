import { defineStore } from 'pinia'

import providerDetailsQuery from '@/graphql/queries/providerDetails.gql'
import type { VehicleTypeData } from '@/types/vehicleTypes.types'
import type { PeriodInterval } from '@/composables/vehicleTypesPeriods'
import type { ModeOrAll } from '@/types/modes.types'

export interface Provider {
  slug: string
  name: string
  citiesByRegions: Record<string, string[]>
  vehicleTypes: VehicleTypeData[]
  locked: boolean
  unlocked?: boolean
  availableInCD: boolean
  nameLocalized: string
  cities: {
    filtered?: string[]
  }
  countries: {
    filtered?: string[]
  }
  regions: {
    filtered?: string[]
  }
  vehicleTypesPeriods: Record<VehicleTypeData, PeriodInterval[]>
}

export const useProviderStore = defineStore('provider', () => {
  const { onCubeLoaded, waitingCube } = useCubeStore()
  const providersStore = useProvidersStore()
  const { setPref, getPref } = usePreferences()

  const route = useRoute()
  const router = useRouter()

  const slug = ref(null)
  const provider = ref<Provider | null>(null)
  const loading = ref(false)
  const hasSlug = computed(() => !!get(slug))

  const { periods, periodsEmpty } = useVehicleTypesPeriods(provider)

  const { load, refetch, onResult } = useLazyQuery(
    providerDetailsQuery,
    computed(() => ({
      provider: get(slug),
    })),
    {
      enabled: hasSlug,
      errorPolicy: 'all',
      returnPartialData: false,
    },
  )

  onResult(async ({ partial, data, errors }) => {
    if (partial) {
      return
    }

    const newProvider = data?.provider || null

    if (newProvider) {
      // update the vehicletype if needed
      const vehicleTypes = newProvider.vehicleTypes
      const vehicleTypesFiltered = vehicleTypes?.filter((type: VehicleTypeData) =>
        DATA_VEHICLE_TYPES.includes(type),
      )

      // checking vehicleType
      const routeMode = route.params.mode as ModeOrAll

      if (vehicleTypesFiltered && routeMode) {
        if (
          vehicleTypesFiltered?.length > 1
          && !vehicleTypesFiltered.includes(ENUM_VEHICLE_TYPES[routeMode])
        ) {
          router.replace({ params: { mode: VEHICLE_TYPE_ALL } })
        } else if (
          vehicleTypesFiltered?.length === 1
          && ENUM_VEHICLE_TYPES[routeMode] !== vehicleTypesFiltered[0]
        ) {
          router.replace({
            params: { mode: getVehicleType(vehicleTypesFiltered[0]) },
          })
        }
      }

      if (!newProvider.slug && !newProvider.name) {
        // provider not found -> 404

        router.replace({ name: 'Providers' })
      } else {
        // the provider exists we can update it
        const { slug, unlocked } = mergeProviderWithCube(newProvider)

        if (slug !== DEMO) {
          // TODO: find a better way to move this
          // save the provider in visited prefs (not saved DEMO page)
          setPref(
            'visited',
            [
              { provider: slug },
              ...(getPref('visited') || []).filter((v: { provider: string }) => v.provider !== slug),
            ].splice(0, 10),
          )

          // TODO: find a solution to remove the route dependency
          if (route.meta.needToBeUnlocked && !unlocked) {
            // the provider is locked -> redirect to footprint

            router.replace({
              name: 'ProviderFootprint',
              params: {
                provider: slug,
                mode: VEHICLE_TYPE_ALL,
              },
            })
          }
        }
      }
    } else {
      set(provider, null)
    }

    if (errors) {
      errors.forEach(e => console.error('settings:', e.message))
    }

    set(loading, false)
  })

  function mergeProviderWithCube(providerData: Provider) {
    const providerInfo = providersStore.getProvider(providerData.slug) || {}
    const merged = {
      ...providerData,
      ...providerInfo,
      countries: {
        ...providerData.countries,
        ...providerInfo.countries,
      },
      citiesByRegions: providersStore.getProviderCitiesByRegions(
        providerData.slug,
      ),
      locked:
        providerData.slug !== DEMO
        && providerData.availableInCD
        && !providerInfo.unlocked,
    }

    set(provider, merged)

    return merged
  }

  function $reset() {
    set(slug, null)
    set(provider, null)
    set(loading, false)
  }

  onCubeLoaded(() => {
    if (provider.value) {
      mergeProviderWithCube(provider.value)
    }
  })

  return {
    slug,
    provider,
    periods,
    periodsEmpty,
    $reset,
    load: async (providerSlug: string) => {
      if (!providerSlug) {
        return
      }

      set(loading, true)
      set(slug, providerSlug)
      await nextTick()

      await waitingCube()

      try {
        return load() || refetch()
      } catch {
        set(loading, false)
      }
    },
    unload: () => {
      set(slug, null)
      set(provider, null)
    },
    isLoading: loading,
  }
})

// make sure to pass the right store definition, `useProviderStore` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProviderStore, import.meta.hot))
}
